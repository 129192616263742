/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import ImgixImage from '@e/imgix-img.connected'
import { Container } from '@uswitch/trustyle.flex-grid'
import getSizes from '@u/get-sizes'

import { pick } from '@u/obj'

const getLinkProps = link =>
  link.includes('http') ? { rel: 'nofollow noreferrer noopener', target: '_blank' } : {}

export default ({
  image = {},
  options = {},
  useContainer = true,
  className = '',
  linkHref = '',
}) => {
  const { theme = {} } = useThemeUI()
  const { container: { lg: { maxWidth = 1024 } = {} } = {} } = theme
  const contentCols = options?.cols || theme?.modules?.image?.contentCols || 12
  const contentSpan = options?.span || theme?.modules?.image?.contentSpan || 12

  const containerStyles = pick(['img', 'textAlign', '&:before', 'position'], options.styles || {})
  const widths = options?.width && [].concat(options.width)

  let sizes
  if (widths) {
    sizes = getSizes(widths, theme.breakpoints)
  }

  const component = (
    <ImgixImage
      critical={options.critical ?? true}
      className={className}
      imgixParams={options?.imgixParams}
      entity={image}
      width={widths ? widths[widths.length - 1] : maxWidth}
      sizes={sizes}
      sx={{
        maxWidth: widths || '100%',
      }}
    />
  )

  const linkProps = getLinkProps(linkHref)

  return (
    <Choose>
      <When condition={useContainer && linkHref}>
        <Container sx={containerStyles} cols={contentCols} span={contentSpan}>
          <a href={linkHref} {...linkProps}>
            {component}
          </a>
        </Container>
      </When>
      <When condition={useContainer && !linkHref}>
        <Container sx={containerStyles} cols={contentCols} span={contentSpan}>
          {component}
        </Container>
      </When>
      <When condition={!useContainer && linkHref}>
        <a href={linkHref} {...linkProps}>
          {component}
        </a>
      </When>
      <Otherwise>{component}</Otherwise>
    </Choose>
  )
}
